<template>
  <div
    ref="dropdown"
    class="group first-of-type:mt-6 last-of-type:mb-4 lg:first-of-type:m-0 lg:last-of-type:m-0"
  >
    <component
      :is="data.reference !== null ? NuxtLink : data.externalLink ? NuxtLink : 'button'"
      :to="data.reference ? localePathByType('PageRecord', data.reference.slug) : data.externalLink"
      :target="data.externalLink && '__blank'"
      noPrefetch
      class="relative mb-3 block w-fit py-1 text-base lg:my-0 lg:py-5 lg:text-xs xl:text-sm 2xl:text-lg"
      :class="[
        {
          'text-teal-500': data.reference !== null && $route.params.slug === data.reference.slug
        },
        data.reference !== null || data.externalLink
          ? 'cursor-pointer'
          : Array.isArray(data.blocks) && data.blocks.length
          ? 'cursor-pointer'
          : 'cursor-auto'
      ]"
      @click="onClick(data)"
    >
      <span
        class="absolute bottom-0 left-0 h-1 transform-gpu bg-teal-500 transition-all duration-300 ease-back-out"
        :class="isSubmenuVisible ? 'w-[calc(100%-26px)] lg:w-full' : ' w-0 lg:w-0'"
      ></span>
      {{ data.title }}

      <Icon
        v-if="Array.isArray(data.blocks) && data.blocks.length"
        name="chevron-down"
        size="sm"
        :color="isSubmenuVisible ? theme.colors.teal[500] : theme.colors.neutral[800]"
        :class="[isSubmenuVisible ? 'rotate-180' : 'rotate-0']"
        class="pointer-events-none transition-transform duration-300 lg:hidden"
      />
    </component>
    <div
      v-if="data.blocks.length > 0"
      class="relative w-full transition-transform duration-500 lg:absolute lg:left-0 lg:top-full lg:-z-1 lg:bg-white lg:shadow-2xl"
      :class="[
        isSubmenuVisible
          ? 'lg:pointer-events-auto'
          : 'lg:pointer-events-none lg:-translate-y-[150%]'
      ]"
    >
      <div class="px-0 lg:px-8">
        <ul
          v-if="Array.isArray(data.blocks) && data.blocks.length > 0"
          class="grid-default gap-x-4 transition-all duration-500 lg:mb-0 lg:gap-x-8 lg:bg-white lg:py-12 lg:delay-500"
          :class="
            isSubmenuVisible
              ? 'mb-6 max-h-full opacity-100'
              : 'mb-0 max-h-0 overflow-hidden opacity-0 lg:max-h-full lg:overflow-auto'
          "
        >
          <li class="col-span-12">
            <ul class="grid auto-cols-auto grid-cols-1 gap-x-4 lg:grid-cols-5 lg:gap-x-8">
              <li v-for="item in data.blocks" :key="item.id">
                <NuxtLink
                  :to="
                    item.reference
                      ? localePathByType(
                          item.reference?.__typename as string,
                          item.reference?.slug as string
                        )
                      : item.externalLink
                  "
                  class="block text-neutral-800"
                  :class="{
                    ' text-teal-500':
                      item.reference !== null && $route.params.slug === item.reference.slug
                  }"
                  noPrefetch
                  @click="onClickChild"
                >
                  <div class="mb-4 text-xl font-bold uppercase transition-colors">
                    {{ item.title }}
                  </div>
                  <div v-if="item.description" class="mb-5 hidden text-sm lg:block">
                    {{ item.description }}
                  </div>
                </NuxtLink>
                <ul
                  v-if="Array.isArray(item.sublinks) && item.sublinks.length"
                  class="hidden lg:block"
                >
                  <li v-for="sublink in item.sublinks" :key="sublink.title" class="w-fit">
                    <NuxtLink
                      :to="
                        sublink.reference
                          ? localePathByType(
                              sublink.reference?.__typename as string,
                              sublink.reference?.slug as string
                            )
                          : sublink.externalLink
                      "
                      class="block text-base font-bold uppercase text-neutral-800 transition-colors hover:text-teal-500"
                      noPrefetch
                      :class="{
                        'text-teal-500':
                          sublink.reference !== null &&
                          $route.params.slug === sublink.reference.slug
                      }"
                      @click="onClickChild"
                    >
                      <div class="mb-2">&angrt; {{ sublink.title }}</div>
                    </NuxtLink>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li
            class="col-span-12 mb-5 mt-6 hidden items-center gap-5 whitespace-nowrap text-base italic after:block after:h-px after:w-full after:bg-neutral-300 lg:flex"
          >
            {{ data.separatorTitle }}
          </li>
          <li class="col-span-12 grid auto-cols-auto grid-cols-1 gap-x-4 lg:grid-cols-5 lg:gap-x-8">
            <div v-for="item in data.blocks" :key="item.id" class="hidden lg:block">
              <ul v-if="Array.isArray(item.secondarySublinks) && item.secondarySublinks.length">
                <li v-for="sublink in item.secondarySublinks" :key="sublink.title" class="w-fit">
                  <NuxtLink
                    noPrefetch
                    :to="
                      sublink.reference
                        ? localePathByType(
                            sublink.reference?.__typename as string,
                            sublink.reference?.slug as string
                          )
                        : sublink.externalLink
                    "
                    class="block text-sm text-neutral-800 transition-colors hover:text-teal-500"
                    :class="{
                      'text-teal-500':
                        sublink.reference !== null && $route.params.slug === sublink.reference.slug
                    }"
                    @click="onClickChild"
                  >
                    <div class="mb-2">&angrt; {{ sublink.title }}</div>
                  </NuxtLink>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { theme } from '#tailwind-config'
import type { MenuRecord } from '~/types/generated'

const NuxtLink = resolveComponent('NuxtLink')
const { isSubmenuVisible, menuHide, submenuHide, submenuToggle } = useMenu()

const { localePathByType } = useLocalePathByType()
const isSubmenuActive = useState('isSubmenuVisible')

defineProps({
  data: {
    type: Object as PropType<MenuRecord>,
    default: () => {}
  },
  idx: {
    type: Number,
    default: 0
  }
})

const dropdown = ref(null)
onClickOutside(dropdown, () => submenuHide())

function onClick(data: object) {
  if (isSubmenuVisible.value) {
    isSubmenuActive.value = false
  } else {
    isSubmenuActive.value = true
  }

  if (Array.isArray(data.blocks) && data.blocks.length) {
    submenuToggle()
  } else {
    menuHide()
  }
}

function onClickChild() {
  submenuHide()
  menuHide()
}

watch(isSubmenuVisible, () => {
  if (!isSubmenuVisible.value) {
    isSubmenuActive.value = false
  }
})

watch(isSubmenuActive, () => {
  if (!isSubmenuActive.value) {
    isSubmenuVisible.value = false
  }
})
</script>
