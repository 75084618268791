<template>
  <div>
    <Header>
      <MenuCorporate
        :class="isMenuMinimized ? 'lg:opacity-0' : 'lg:opacity-100'"
        :data="data.allMenuCorporates"
        class="order-2 transition-opacity duration-500 lg:order-1"
      />
      <hr
        class="-mx-4 border-neutral-300 lg:order-2 lg:-mx-8 lg:w-[calc(100%+64px)]"
        :class="isMenuMinimized ? 'lg:opacity-0' : 'lg:opacity-100'"
      />
      <MenuList
        :class="isMenuMinimized ? 'pointer-events-none lg:opacity-0' : 'lg:opacity-100'"
        :data="data.allMenus"
        class="transition-opacity duration-500 lg:order-2"
      />
      <MenuCta :data="data.allMenuCtas" class="order-3" />
    </Header>
    <ScrollSmoother>
      <main id="main" class="pt-16 lg:pt-[6.125rem] xl:pt-[6.375rem] 2xl:pt-[6.875rem]">
        <slot />
      </main>
      <LazyFooter :data="data" />
    </ScrollSmoother>
    <CookieBanner />
  </div>
</template>

<script setup lang="ts">
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import locales from '~/i18n/locales'

const isMenuMinimized = useState('menuMinimized')

const { locale } = useI18n()
const langAttr = computed(() => locales.find(l => l.code === locale.value).code)

defineProps({
  data: {
    type: Object,
    default: () => {}
  }
})

onMounted(() => {
  document.body.classList.add('overflow-visible')

  useTimeoutFn(() => {
    ScrollTrigger.refresh()
  }, 200)
})

useHead({
  htmlAttrs: {
    lang: langAttr
  }
})
</script>
