<template>
  <div
    v-if="Array.isArray(data) && data.length"
    class="menuCta lg:basis-[10%] flex flex-col gap-[10px] pt-8 lg:w-fit lg:flex-row lg:pt-0"
  >
    <template v-for="button, index in data" :key="button.id">
      <Button
        v-if="button"
        :label="button.title && button.title"
        :to="
          button.pageLink !== null
            ? localePathByType(
              button.pageLink?.__typename as string,
              button.pageLink?.slug as string
              )
            : button.externalLink
        "
        :target="button.externalLink ? '_blank' : undefined"
        :variant="button.primary ? 'primary' : 'secondary'"
        :size="isXL ? 'medium' : 'small'"
        :class="{ '!border-white !text-white !mix-blend-difference': isMenuMinimized }"
        :style="
          index === 0
            ? { '--hover-background': theme.colors.orange[500] }
            : ''
        "
        :filled="index === 1"
        :theme="index === 1 ? 'dark' : 'light'"
        noPrefetch
        @click="menuHide"
      />
    </template>
  </div>
</template>

<script setup lang="ts">
import type { CtaRecord } from '~/types/generated'
import { theme } from '#tailwind-config'

const breakpoints = useBreakpoints(theme.screens)
const isXL = breakpoints.greater('xl')

const { menuHide } = useMenu()
const { localePathByType } = useLocalePathByType()
const isMenuMinimized = useState('menuMinimized')

defineProps({
  data: {
    type: Array as PropType<CtaRecord[]>,
    default: () => {}
  }
})
</script>

<style lang="postcss">
.menuCta a {
  width: 100% !important;
}
</style>
