<template>
  <div class="flex justify-between gap-1 flex-col lg:flex-row lg:ml-16 lg:basis-[64%] xl:basis-[66%] 2xl:basis-70%">
    <nav class="!hidden lg:!flex items-center">
      <template v-for="(item, idx) in primaryListItems" :key="idx">
        <MenuListItemSimple :data="item" />
        <span class="inline-block mx-2 xl:mx-4 text-neutral-800 text-base lg:text-xs xl:text-sm 2xl:text-lg last:hidden"
          >&#47;</span
        >
      </template>
    </nav>
    <SectionSplitTitle class="!py-0 -mx-4 lg:!hidden" :data="headerSplitData" />
    <nav class="flex flex-col lg:flex-row lg:items-center mt-2 lg:mt-0">
      <template v-for="(item, idx) in secondaryListItems" :key="idx">
        <MenuListItem :idx="idx" :data="item" />
        <span
          class="hidden lg:inline-block mx-2 xl:mx-4 text-neutral-800 text-base lg:text-xs xl:text-sm 2xl:text-lg last:hidden"
          >&#47;</span
        >
      </template>
    </nav>
  </div>
</template>

<script setup lang="ts">
import type { MenuRecord } from '~/types/generated'

const props = defineProps({
  data: {
    type: Array as PropType<MenuRecord[]>,
    default: () => []
  }
})

// Filter primary list items
const primaryListItems = computed(() => props.data.filter((item: MenuRecord) => item.large))

// Filter secondary list items
const secondaryListItems = computed(() => props.data.filter((item: MenuRecord) => !item.large))

// Colors for split list menu
const splitColors = ['#212121', '#661D88', '#315B80', '#D65A1A']

const primaryListItemsMapped = computed(() =>
  primaryListItems.value.map((el, index) => ({ ...el, color: { hex: splitColors[index] ?? '' } }))
)
const headerSplitData = computed(() => {
  return {
    _modelApiKey: 'section_split_title',
    id: '180996890',
    links: primaryListItemsMapped.value
  }
})
</script>
