<template>
  <NuxtLink
    class="py-3 text-base uppercase text-neutral-800 transition-all duration-300 lg:py-0 lg:text-xs bg-gradient-to-r from-white to-white bg-[length:0%_1px] bg-left-bottom bg-no-repeat hover:bg-[length:100%_1px]"
    :class="{
      'text-teal-500': data.reference !== null && $route.params.slug === data.reference.slug
    }"
    :to="data.reference ? localePathByType('PageRecord', data.reference.slug) : data.externalLink"
    noPrefetch
    @click="menuHide"
  >
    {{ data.title }}
  </NuxtLink>
</template>

<script setup lang="ts">
const { localePathByType } = useLocalePathByType()
const { menuHide } = useMenu()

defineProps({
  data: {
    type: Object,
    default: () => {}
  }
})
</script>
