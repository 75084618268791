<template>
  <div
    class="fixed left-0 top-16 h-screen w-full overflow-hidden transition-all duration-300 ease-linear"
    :class="isVisible ? 'bg-white' : 'invisible'"
  ></div>
</template>

<script setup lang="ts">
const isVisible = useState('menu')
</script>
