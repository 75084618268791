<template>
  <NuxtLink
    class="block text-base uppercase italic text-neutral-800 transition-colors duration-300 hover:text-teal-500 lg:text-xs xl:text-sm 2xl:text-lg"
    :class="{
      'text-teal-500': data.reference !== null && $route.params.slug === data.reference.slug
    }"
    :to="data.reference ? localePathByType('PageRecord', data.reference.slug) : data.externalLink"
    noPrefetch
  >
    {{ data.title }}
  </NuxtLink>
</template>

<script setup lang="ts">
const { localePathByType } = useLocalePathByType()

defineProps({
  data: {
    type: Object,
    default: () => {}
  }
})
</script>
