<template>
  <header
    ref="headerElement"
    class="fixed top-0 z-10 min-h-[64px] w-full transition-all duration-200 2xl:min-h-[110px]"
    :class="[
      isMenuMinimized && !store.isSimpleMenu ? 'header--minimized bg-transparent' : 'bg-white',
      isMenuMinimized && minimizedWithDelay ? 'lg:mix-blend-difference' : '',
      store.isSimpleMenu && '!min-h-fit !mix-blend-normal',
      isMounted ? 'opacity-100' : 'opacity-0'
    ]"
  >
    <template v-if="!store.isSimpleMenu">
      <MenuBackDrop />

      <Transition name="menu">
        <div
          v-show="isMenuVisible"
          class="flex h-[100dvh] w-full touch-pan-y flex-col overflow-y-auto overflow-x-hidden border-b px-4 pb-20 pt-16 lg:static lg:!flex lg:h-auto lg:flex-row lg:flex-wrap lg:items-center lg:justify-between lg:px-8 lg:pb-0 lg:pt-0"
          :class="[isMenuMinimized ? 'border-transparent' : 'border-neutral-300 bg-white']"
        >
          <slot />
        </div>
      </Transition>
      <div class="absolute top-0 z-10 h-16 w-full bg-white lg:h-0">
        <Logo
          class="absolute left-4 top-4 z-10 !h-8 !w-8 lg:left-8 lg:top-[54px] xl:top-[56px] 2xl:top-[60px]"
          :class="{ 'mix-blend-difference invert': isMenuMinimized }"
          :color="isSubmenuVisible ? 'teal' : 'black'"
        />
        <MenuButton />
        <Transition name="fade">
          <div
            class="absolute right-14 top-5 lg:right-10 lg:top-2 lg:block"
            :class="isMenuVisible ? 'block' : 'hidden'"
          >
            <LanguageDropdown class="!text-black lg:!text-white" is-icon-type reverse-direction />
          </div>
        </Transition>
      </div>
    </template>
    <div v-else class="border-b border-neutral-300">
      <div class="container flex items-center justify-between py-4">
        <Logo
          class="!h-9 !w-9"
          :class="{ 'mix-blend-difference invert': isMenuMinimized }"
          :color="isSubmenuVisible ? 'teal' : 'black'"
        />

        <Button
          v-if="store.simpleMenuCta"
          :label="store.simpleMenuCta.label"
          size="large"
          variant="secondary"
          filled
          theme="dark"
          :to="
            store.simpleMenuCta.intLink !== null
              ? localePathByType(
                  store.simpleMenuCta.intLink?.__typename as string,
                  store.simpleMenuCta.intLink?.slug as string
                )
              : store.simpleMenuCta.extLink
          "
        />
      </div>
    </div>
  </header>
</template>

<script setup lang="ts">
import { useDebounceFn } from '@vueuse/core'
import { theme } from '#tailwind-config'
import { useAppStore } from '~/stores/AppStore'

const { localePathByType } = useLocalePathByType()
const isMenuVisible = useState('menu')
const isSubmenuVisible = useState('isSubmenuVisible')
const isMenuMinimized = useState('menuMinimized')
const { menuHide, submenuHide } = useMenu()
const breakpoints = useBreakpoints(theme.screens)
const isScreenLg = breakpoints.greater('lg')
const route = useRoute()
const isMounted = ref(false)

const headerElement = ref()
const headerOffset = ref(0)
const minimizedWithDelay = ref(false)
const store = useAppStore()

// Handle window resize if menu is visible
const handleResize = useDebounceFn(() => {
  if (isScreenLg.value && isMenuVisible.value) {
    menuHide()
  }
}, 400)

const lastScrollTop = ref(0)

const onScroll = useDebounceFn(() => {
  if (process.client) {
    const st = window.scrollY || document.documentElement.scrollTop
    if (st > lastScrollTop.value && st > 200) {
      // downscroll
      isMenuMinimized.value = true
      isSubmenuVisible.value = false

      useTimeoutFn(() => {
        minimizedWithDelay.value = true
      }, 250)
    } else if (st < lastScrollTop.value) {
      // upscroll
      isMenuMinimized.value = false
      isSubmenuVisible.value = false

      useTimeoutFn(() => {
        minimizedWithDelay.value = false
      }, 250)
    }

    lastScrollTop.value = st <= 0 ? 0 : st // For Mobile or negative scrolling
  }
}, 5)

onMounted(() => {
  window.addEventListener('resize', handleResize)
  window.addEventListener('scroll', onScroll)
  headerOffset.value = headerElement.value.offsetTop
  isMounted.value = true
})

onUnmounted(() => {
  window.removeEventListener('resize', handleResize)
  window.removeEventListener('scroll', onScroll)
})

watch(
  () => route.fullPath,
  () => {
    submenuHide()
    isSubmenuVisible.value = false
  }
)
</script>

<style lang="postcss">
.header--minimized {
  transform: translateY(-64px);

  @screen lg {
    transform: translateY(-36px);
  }
}
</style>
