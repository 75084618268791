<template>
  <div id="smooth-wrapper" ref="wrapper">
    <div id="smooth-content" ref="content">
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">
import { gsap } from 'gsap'
import { ScrollSmoother, ScrollTrigger } from 'gsap/all'
import { useAppStore } from '~/stores/AppStore'
import { theme } from '#tailwind-config'

const breakpoints = useBreakpoints(theme.screens)
const isLG = breakpoints.greaterOrEqual('lg')
const { isMobileOrTablet } = useDevice()

const nuxtApp = useNuxtApp()

const wrapper = ref<HTMLElement | null>(null)
const content = ref<HTMLElement | null>(null)

const smoother = ref<null | any>(null)
const store = useAppStore()

onMounted(async () => {
  if (!isLG.value || isMobileOrTablet) {
    return
  }

  await nextTick(() => {
    const mm = gsap.matchMedia()
    if (wrapper.value && content.value) {
      gsap.registerPlugin(ScrollSmoother, ScrollTrigger)
      mm.add('(min-width: 1367px)', () => {
        smoother.value = ScrollSmoother.create({
          wrapper: wrapper.value,
          content: content.value,
          // speed: 1,
          smooth: isLG.value ? 1.5 : 1,
          ignoreMobileResize: true,
          normalizeScroll: {
            allowNestedScroll: true
          }
        })

        smoother.value.paused(true)

        ScrollTrigger.refresh()
        smoother.value.paused(false)

        content.value?.classList.add('will-change-transform')
      })
    }
  })
})

watch(
  () => store.lockSmoothScroll,
  () => {
    if (store.lockSmoothScroll) {
      smoother.value?.paused(true)
    } else {
      smoother.value?.paused(false)
    }
  }
)

nuxtApp.hooks.hook('page:finish', async () => {
  await nextTick()
  smoother.value?.scrollTo(0, false)
})

onUnmounted(() => {
  smoother?.value?.kill()
  ScrollTrigger.refresh()
})
</script>
