import { defineStore } from 'pinia'

export const useCookiesStore = defineStore('CookiesStore', {
  state: () => ({
    isBannerVisible: false,
    showPreferences: false
  }),
  actions: {
    setIsBannerVisible(bool: boolean) {
      this.isBannerVisible = bool
    },
    setShowPreferences(bool: boolean) {
      this.showPreferences = bool
    }
  }
})
