<template>
  <div ref="dropdown" class="relative text-white">
    <button
      id="languageToggler"
      class="inline-flex items-center justify-center gap-[2px] text-sm font-bold outline-none"
      :aria-label="$t('languageToggle')"
      @click="isActive = !isActive"
    >
      <span v-if="!isIconType">{{ activeLocale }}</span>
      <Icon
        v-if="!isIconType"
        class="transition-transform duration-300"
        name="chevron-down"
        size="sm"
        :color="isActive ? theme.colors.teal[500] : 'white'"
        :class="{ 'rotate-180': isActive }"
      />

      <Icon v-if="isIconType" class="transition-transform duration-300" name="globe" size="md" />
    </button>

    <Transition name="fade">
      <ul
        v-if="isActive"
        class="absolute left-0 min-w-[160px] rounded-3xl bg-white p-4 shadow-lg lg:left-auto lg:right-0"
        :class="reverseDirection ? 'bottom-auto left-auto right-0 top-8' : 'bottom-10'"
        aria-labelledby="languageToggler"
      >
        <li v-for="item in locales" :key="item.code" class="mt-4 inline-block w-full first:mt-0">
          <button
            :hreflang="item.code"
            class="flex w-full items-center justify-between gap-3 text-sm text-neutral-900"
            :class="{ 'font-bold': item.code === locale }"
            :disabled="item.code === locale"
            @click="switchLanguage(item.code)"
          >
            {{ item.name }}
            <Icon
              v-if="item.code === locale"
              name="check"
              :color="theme.colors.teal[500]"
              size="sm"
              class="transition-transform duration-300"
            />
          </button>
        </li>
      </ul>
    </Transition>
  </div>
</template>

<script setup lang="ts">
import { LocaleObject } from '@nuxtjs/i18n/dist/runtime/composables'
import { theme } from '#tailwind-config'

defineProps({
  reverseDirection: {
    type: Boolean,
    default: false
  },
  isIconType: {
    type: Boolean,
    default: false
  }
})

const { locale, locales } = useI18n()
const isActive = ref(false)

const dropdown = ref(null)
onClickOutside(dropdown, () => hideDropdown())

const activeLocale = computed(
  () => locales.value.find((l: LocaleObject) => l.code === locale.value).name
)

function hideDropdown() {
  isActive.value = false
}

const switchLanguage = (lang: string) => {
  const language = useCookie('nf_country')
  language.value = lang

  if (lang === 'en') {
    window.location.replace('/')
  } else {
    window.location.replace(`/${lang}`)
  }

  isActive.value = false
}
</script>
